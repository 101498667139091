import * as React from 'react';
import './index.css'
import MenuRail from "../../Common/MenuRail";
import {MenuOption, PortalProperties, Principal} from "../../../models/models";
import {Button, Grid, Segment} from "semantic-ui-react";
import {acceptTerms} from "../../../services/terms-service";
import {getBackgroundImage} from "../../../util/functions";
import toastMessage from "../../../util/toast";
import {ToastComponent} from "../../Common/ToastComponent";
import {info} from "../../../services/route-service";
import ReactGA from "react-ga4";

interface PageProps {
    userCookie: any
    userPrincipal: Principal;
    history: any
    selectedTopMenuOption: string
    setSelectedTopMenuOption: (selectedTopMenuOption: string) => void;
    portalProperties: PortalProperties;
    railDisplay: string
    setRailDisplay: (railDisplay: string) => void
    menuOptions: MenuOption[]
    darkTheme: boolean
    termsAccepted: boolean
    getUserPrincipal: any
}

interface PageState {}

export default class TermsOfUse extends React.Component<PageProps, PageState> {
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.state = {}
    }

    componentDidMount() {
        ReactGA.event({category: "user_page_view", action: "View Terms Of User"});
    }

    componentDidUpdate(prevProps: Readonly<PageProps>, prevState: Readonly<PageState>, snapshot?: any) {
        if(this.props.termsAccepted !== prevProps.termsAccepted)
            this.props.history.push(info.home.path)
    }

    handleAcceptTerms = () => {
        acceptTerms(this.props.userCookie).then(response => {
            if (response.ok)
                this.props.getUserPrincipal()
            else
                toastMessage.error(response.message)
        })
    }

    render(){
        return(
            <div className={'background-image-tou'} style={{backgroundImage: getBackgroundImage(this.props.darkTheme)}} >
                <MenuRail selectedTopMenuItem={this.props.selectedTopMenuOption}
                          railDisplay={this.props.railDisplay}
                          setRailDisplay={this.props.setRailDisplay}
                          userPrincipal={this.props.userPrincipal}
                          portalProperties={this.props.portalProperties}
                          history={this.props.history}
                          menuOptions={this.props.menuOptions}
                          setSelectedTopMenuOption={this.props.setSelectedTopMenuOption}/>
                <ToastComponent/>
                <Grid style={{height: '100%'}} stackable>
                    <Grid.Column style={{padding: '5rem'}}>
                        <Segment.Group className={this.props.darkTheme? 'dark' : ''} id={'tou-sg'} >
                            <Segment
                                content={"Terms and Conditions of Use for SPP'S Portal Website"}
                                className={'chart-title-sg'}/>
                            <Segment id={'tou-body-sg'}>
                                <h3 className={'tou-header'}>
                                    General Legal Terms
                                </h3>
                                <p className={'tou-body'}>
                                    All information posted or otherwise
                                    available on Southwest Power Pool, Inc.'s ("SPP") Portal Website
                                    (the "Portal") is the exclusive copyrighted material of the respective
                                    author(s) or SPP and access to Portal does not confer any license or ownership
                                    interest in either the form or content of the Portal, including,
                                    without limitation, confidential or proprietary information or
                                    intellectual property. Permission is implicitly granted to copy
                                    and distribute (via computer network or printed form) in whole
                                    or in part (with appropriate citation) EXCEPT when such
                                    materials will be used, in whole or in part, within a commercial
                                    publication (printed or otherwise) or when the author(s) or SPP
                                    will be quoted in commercial materials, forums, or publications.
                                    Commercial use of any information contained on the Portal
                                    requires express written authorization from the author(s) or a
                                    duly authorized officer of SPP.
                                </p>
                                <p className={'tou-body'}>
                                    Southwest Power Pool, Inc. (SPP)
                                    hereby disclaims any warranty, express or implied, as to the
                                    accuracy, completeness, timeliness or availability of the
                                    information provided by this Portal. SPP has made every effort
                                    to provide the most current and correct information available.
                                    Use of the information within this Portal in any manner
                                    constitutes an agreement to hold harmless and indemnify the
                                    members of SPP or their employees, the employees of SPP, as well
                                    as any consultants or entities performing work with or for SPP
                                    or its member base from all claims of any damages or liability
                                    for actual, indirect, incidental, special or consequential
                                    damages sustained or incurred in connection with the use of, or
                                    inability to use the information provided. SPP reserves the
                                    right to revise, supplement or delete information without prior
                                    notification to users.
                                </p>
                                <p className={'tou-body'}>
                                    Neither SPP nor any agency thereof,
                                    nor any of their employees, makes any warranty, express or
                                    implied, or assumes any legal liability or responsibility for
                                    the accuracy, completeness, or usefulness of any information,
                                    apparatus, product or process disclosed, or represents that its
                                    use would not infringe upon privately owned rights. Reference
                                    herein to any specific commercial product, process, or service
                                    by trade name, trademark, manufacturer, or otherwise, does not
                                    necessarily constitute or imply its endorsement, recommendation,
                                    or favoring by SPP or any agency thereof. SPP does not warrant
                                    the accuracy, interruption, capability or functionality of any
                                    of the information, products or services mentioned within this
                                    document. Users are advised to verify the accuracy of this
                                    information with the original source of the data.
                                </p>
                                <p className={'tou-body'}>
                                    Material contained herein is to be used for the benefit of SPP
                                    and/or its membership and users but is not limited to SPP and/or
                                    its membership and users. Upon request, SPP will remove any
                                    incorrect or unusable information from the Portal. Any such
                                    request or general inquiries about the Portal or these terms
                                    should be sent via e-mail to <a href="mailto:questions@spp.org">questions@spp.org</a>.
                                    Any inquiries about public communications should be directed to
                                    the SPP Manager of Communications, 201 Worthen Drive, Little
                                    Rock, AR 72223, telephone (501) 614-3200, or <a
                                    href="mailto:questions@spp.org">questions@spp.org</a>.
                                </p>
                                <p className={'tou-body'}>
                                    Links to SPP's Portal from other web sites are permitted.
                                </p>
                                <p className={'tou-body'}>
                                    SPP may post information from third parties or link to third
                                    party external websites. The opinions or position contained on
                                    third party websites or contained in third party information are
                                    those of the individual author, and may not represent the
                                    opinion or positions of SPP. <b>SPP is not responsible for
                                    the content or the privacy policies of external web sites
                                    linked to this Portal and user assumes all risk for damages of
                                    any kind, including, but not limited to, direct, indirect,
                                    general, special, incidental or consequential damages arising
                                    out of any use of the information contained therein.</b>
                                </p>
                                <h3 className={'tou-header'}>
                                    Privacy Policy
                                </h3>
                                <p className={'tou-body'}>Users may be asked to disclose
                                    personally identifiable information such as name, email address,
                                    phone number or other business related information. SPP shall
                                    use such personally identifiable information for business
                                    purposes only; and will not provide personally identifiable
                                    information to any third, or grant permission to other parties
                                    to release personally identifiable information, unless prior
                                    permission has been obtained to do so. However, SPP reserves the
                                    right to disclose user's personally identifiable information to
                                    the extent required by law.</p>
                                <h3 className={'tou-header'}>
                                    Acceptable Use Policy
                                </h3>
                                <p className={'tou-body'}>
                                    <a href={this.props.portalProperties.termsOfUsePolicyLink}>
                                        {this.props.portalProperties.termsOfUsePolicyLink}
                                    </a>
                                </p>
                            </Segment>
                            <Button className={'narrow-btn tou-btn'}
                                    onClick={this.handleAcceptTerms}
                                    attached={'bottom'}
                                    color={'green'}>
                                ACCEPT TERMS OF USE
                            </Button>
                        </Segment.Group>
                    </Grid.Column>
                </Grid>
            </div>
        )
    }
}